
import { ScoreType } from '@/models/Score';
import Statistics from '@/models/Statistics';
import { computed, defineComponent, PropType } from 'vue';
import scoreHelper from '@/helpers/Score.helper';
import ScoreCircular from '../charts/ScoreCircular.vue';
import ScoreAccordion from '../charts/ScoreAccordion.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';

export default defineComponent({
  components: { ScoreCircular, ScoreAccordion, HeadingComponent },
  props: {
    title: {
      type: String,
      default: '',
    },
    statistics: {
      type: Object as PropType<Statistics | undefined>,
    },
  },
  setup(props) {
    const efficiency = computed(() => props.statistics?.scores?.find((item) => item.type == ScoreType.EFFICIENCY));
    const minGlucose = computed(() => props.statistics?.scores?.find((item) => item.type == ScoreType.MIN_GLUCOSE));
    const maxGlucose = computed(() => props.statistics?.scores?.find((item) => item.type == ScoreType.MAX_GLUCOSE));
    const avgGlucose = computed(() => props.statistics?.scores?.find((item) => item.type == ScoreType.AVG_GLUCOSE));
    const coefficientOfVariation = computed(() =>
      props.statistics?.scores?.find((item) => item.type == ScoreType.COEFFICIENT_OF_VARIATION),
    );
    const timeInRange = computed(() => props.statistics?.scores?.find((item) => item.type == ScoreType.TIME_IN_RANGE));
    const fastingGlucose = computed(() =>
      props.statistics?.scores?.find((item) => item.type == ScoreType.FASTING_GLUCOSE),
    );
    return {
      scoreHelper,
      minGlucose,
      maxGlucose,
      efficiency,
      avgGlucose,
      coefficientOfVariation,
      timeInRange,
      fastingGlucose,
    };
  },
});
