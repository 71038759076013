import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-cgm__scores" }
const _hoisted_2 = { class: "c-card-score" }
const _hoisted_3 = { class: "c-card__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_ScoreCircular = _resolveComponent("ScoreCircular")!
  const _component_ScoreAccordion = _resolveComponent("ScoreAccordion")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.title,
      "sub-heading": true,
      divider: false
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('cgm-scores.average-description')), 1),
        _createVNode(_component_ScoreCircular, {
          class: "c-card__circular-score",
          score: _ctx.efficiency?.value,
          color: _ctx.scoreHelper.scoreColor(_ctx.efficiency?.value)
        }, null, 8, ["score", "color"])
      ]),
      (_ctx.minGlucose)
        ? (_openBlock(), _createBlock(_component_ScoreAccordion, {
            key: 0,
            class: "c-accordion",
            score: _ctx.minGlucose
          }, null, 8, ["score"]))
        : _createCommentVNode("", true),
      (_ctx.fastingGlucose)
        ? (_openBlock(), _createBlock(_component_ScoreAccordion, {
            key: 1,
            class: "c-accordion",
            score: _ctx.fastingGlucose
          }, null, 8, ["score"]))
        : _createCommentVNode("", true),
      (_ctx.maxGlucose)
        ? (_openBlock(), _createBlock(_component_ScoreAccordion, {
            key: 2,
            class: "c-accordion",
            score: _ctx.maxGlucose
          }, null, 8, ["score"]))
        : _createCommentVNode("", true),
      (_ctx.avgGlucose)
        ? (_openBlock(), _createBlock(_component_ScoreAccordion, {
            key: 3,
            class: "c-accordion",
            score: _ctx.avgGlucose
          }, null, 8, ["score"]))
        : _createCommentVNode("", true),
      (_ctx.coefficientOfVariation)
        ? (_openBlock(), _createBlock(_component_ScoreAccordion, {
            key: 4,
            class: "c-accordion",
            score: _ctx.coefficientOfVariation
          }, null, 8, ["score"]))
        : _createCommentVNode("", true),
      (_ctx.timeInRange)
        ? (_openBlock(), _createBlock(_component_ScoreAccordion, {
            key: 5,
            class: "c-accordion",
            score: _ctx.timeInRange
          }, null, 8, ["score"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}