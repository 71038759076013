
import { Serie } from '@/models/Statistics';
import { computed, defineComponent, nextTick, onUnmounted, PropType, watchEffect } from 'vue';
import Chart from 'chart.js/auto';
import { EventDto, EventScoreDto } from '@/models/Event';
import dateFormat from '@/helpers/DateFormat.helper';
import { chartHelper } from '@/helpers/Chart.helper';
import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { useCgmDetailStore } from '@/store/cgmDetail.module';

const GRAPH_HEIGHT = 240;

export default defineComponent({
  emits: ['annotation-event-click'],
  props: {
    chartId: {
      type: String,
      required: true,
      default: () => {
        return 'day-glucose-chart';
      },
    },
    serie: {
      type: Object as PropType<Serie | undefined>,
    },
    events: {
      type: Array as PropType<EventScoreDto[] | EventDto[] | undefined>,
    },
    navigationBasePath: {
      type: String,
    },
    clickableAnnotations: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    chartStartEndDates: {
      type: Object as PropType<{ startDateChart: string; endDateChart: string }>,
    },
  },
  setup(props, context) {
    Chart.register(annotationPlugin);
    const storeCGM = useCgmDetailStore();
    const patientTimezone = computed(() => storeCGM.getMedicalTestSelectedUserTimezone);
    let chart: Chart | null = null;

    watchEffect(() => {
      handleDataChange(props.serie, props.events);
    });

    onUnmounted(() => {
      if (chart) {
        chart.destroy();
      }
    });

    function getAxisFormatted(axis: string[]) {
      return axis.map((date: any) => {
        return moment(dateFormat.utcToTimezone(date, patientTimezone.value)).valueOf();
      });
    }

    async function handleDataChange(serie: Serie | undefined, events: EventScoreDto[] | EventDto[] | undefined) {
      if (chart) {
        chart?.destroy();
      }

      if (!serie || !serie.data) {
        return;
      }

      const container = document.getElementById(props.chartId);
      if (!container) {
        await nextTick(() => {
          drawChart(serie, events);
        });
      } else {
        drawChart(serie, events);
      }
    }

    function drawChart(serie: Serie, events: EventScoreDto[] | EventDto[] | undefined) {
      const container = document.getElementById(props.chartId);
      if (!container) {
        return;
      }
      const canvas = container as HTMLCanvasElement;
      if (!canvas) {
        return;
      }
      const maxValueSerie = serie ? Math.max(...serie.data) : 180;
      const annotation =
        events && events.length > 0
          ? chartHelper.getEventsAnnotation({
              context,
              maxValue: maxValueSerie,
              clickable: props.clickableAnnotations,
              events,
              timezone: patientTimezone.value,
            })
          : null;

      const chartConfig: any = {
        type: 'line',
        data: getChartData(serie),
        options: getChartOptions(serie, annotation),
      };

      chart = new Chart(canvas, chartConfig);
    }

    function getChartData(serie: Serie): any {
      if (!serie) {
        return {};
      }

      return {
        labels: getAxisFormatted(serie.xaxis),
        datasets: [
          {
            label: '',
            data: serie.data,
            borderColor: '#8459C0',
            showLine: true,
            tension: 0.4,
            backgroundColor: chartHelper.calculateGradient(props.chartId, GRAPH_HEIGHT),
            fill: true,
            spanGaps: 1000 * 60 * 30, // if there is a gap of more than 30min between two datapoints, the line will break to reflect that some data is missing
          },
        ],
      };
    }

    function getChartOptions(serie: Serie, annotation: any): any {
      return {
        animation: {
          duration: 0,
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 2,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            titleColor: '#233D4D',
            backgroundColor: '#ffffff',
            bodyColor: '#2E194B',
            bodyFont: {
              familiy: 'Rubik',
              weight: 'bold',
              size: 14,
            },
            titleFont: 'Rubik',
            cornerRadius: 4,
            padding: 8,
            usePointStyle: 'circle',
            callbacks: {
              label: function (text: any) {
                return text.parsed.y + ' mg/dL';
              },
              title: function (tooltipItems: any) {
                return dateFormat.formatDateWithHour(tooltipItems[0].parsed.x);
              },
            },
          },
          annotation: annotation,
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            suggestedMin: props.chartStartEndDates?.startDateChart,
            suggestedMax: props.chartStartEndDates?.endDateChart,
            display: serie.xaxis.length > 0,
            title: {
              display: true,
            },
            type: 'time',
            time: {
              unit: 'hour',
            },
            grid: {
              display: 'false',
              drawOnChartArea: false,
              drawBorder: true,
              drawTicks: false,
            },
            ticks: {
              color: '#545454',
              padding: 8,
              font: {
                size: 12,
                family: 'Rubik',
              },
            },
          },
          y: {
            suggestedMin: 40,
            suggestedMax: 210,
            grid: {
              display: 'true',
              drawOnChartArea: true,
              drawBorder: true,
              drawTicks: false,
            },
            ticks: {
              color: '#545454',
              padding: 8,
              font: {
                size: 12,
                family: 'Rubik',
              },
            },
          },
        },
      };
    }
  },
});
