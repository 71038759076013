import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "c-chart__container" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.chartId)
      ? (_openBlock(), _createElementBlock("canvas", {
          key: 0,
          id: _ctx.chartId
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}